import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class StateOrProvincesService extends ServiceBase {
  stateOrProvincesServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.stateOrProvincesServiceUrl = this.apiBaseUrl +  'stateorprovinces/';
  }

  get(countryCode){
    return this.http.get(this.stateOrProvincesServiceUrl + countryCode);
  }
}
