<div class="row pb-5">
  <div class="col-md-12">
    <div class="row banner">
      <div class="col-md-12">
        <h5 id="h1CompanyName" class="py-3" *ngIf="claimScreenDetail !== undefined && claimScreenDetail.description !== undefined">
          {{claimScreenDetail.description}}
        </h5>
      </div>
    </div>
    <div class="row pl-3 pt-3">
      <div class="col-md-12">
        <form [formGroup]="claimDetailForm" (ngSubmit)="onSubmit($event.submitter.id)">
          <div formArrayName="fieldArray"
               *ngFor="let fg of fieldArray.controls; let i=index">
            <div [formGroupName]="i">
              <div *ngFor="let field of claimScreenDetailFields; let j=index">
                <div *ngIf="field.fieldType == 'Radio' && i === 0" class="form-group row">
                  <div class="col-md-12">
                    <p><b>{{field.fieldDescription}}</b></p>
                    <div class="custom-control custom-radio form-group" *ngFor="let fieldValue of field.fieldValues; let k=index">
                      <input class="custom-control-input" type="radio"
                             id="{{'radio-' + fieldValue.id}}" value="{{fieldValue.id}}"
                             formControlName="{{'field' + field.fieldId}}">
                      <label class="custom-control-label" for="{{'radio-' + fieldValue.id}}">{{fieldValue.name}}</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'ReasonText'" 
                     class="form-group row">                    
                  <div class="col-md-3">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" value="{{field.fieldValue}}"
                           id="{{'field-' + i + '-' + field.fieldId}}" placeholder="Maximum of 50 characters allowed" 
                           class="form-control form-control-sm" maxlength="50"
                           formControlName="{{'field' + field.fieldId}}" (change)="field.fieldDescription === 'Policy Number:' && onPolicyChange(field.fieldId)" />
                           <em class="text-danger" *ngIf="isMaxLengthExceededForText(field.fieldId, i, 200)">Exceeded 50 characters</em>
                           <em class="text-danger" *ngIf="hasValue(field.fieldId, i)">{{displayCharCountForText(field.fieldId, i, 50)}} character(s) left</em>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>
                <div *ngIf="field.fieldType == 'Text' && visibleFields.indexOf(field.fieldId) !== -1" 
                     class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5">
                    <input type="text" value="{{field.fieldValue}}"
                           id="{{'field-' + i + '-' + field.fieldId}}"
                           class="form-control form-control-sm" [attr.disabled]="disabledAfterTransmit()"
                           formControlName="{{'field' + field.fieldId}}" (change)="field.fieldDescription === 'Policy Number:' && onPolicyChange(field.fieldId)" />
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>
                <div *ngIf="field.fieldType == 'ZipCode' && visibleFields.indexOf(field.fieldId) !== -1" 
                     class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5">
                    <input type="text" value="{{field.fieldValue}}"
                           id="{{'field-' + i + '-' + field.fieldId}}"
                           class="form-control form-control-sm" [attr.disabled]="disabledAfterTransmit()"
                           formControlName="{{'field' + field.fieldId}}" (change)="onZipCodeChange(field.fieldId)" />
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>
                <div *ngIf="field.fieldType == 'TextReadOnly' && visibleFields.indexOf(field.fieldId) !== -1" 
                    class="form-group row">                    
                    <div class="col-md-6">
                      <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                        {{field.fieldDescription}}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input type="text" value="{{field.fieldValue}}" readonly
                              id="{{'field-' + i + '-' + field.fieldId}}"
                              class="form-control form-control-sm"
                              formControlName="{{'field' + field.fieldId}}" />
                      </div>
                </div>
                <div *ngIf="field.fieldType == 'Label' && visibleFields.indexOf(field.fieldId) !== -1" 
                     class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <span id="{{'field-' + i + '-' + field.fieldId}}"
                           class="form-control form-control-sm label" >
                           {{field.fieldValue}}
                           </span>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'Phone' && visibleFields.indexOf(field.fieldId) !== -1" 
                  class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" value="{{field.fieldValue}}" appPhoneMask pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4,}$" 
                            id="{{'field-' + i + '-' + field.fieldId}}"
                            class="form-control form-control-sm" [attr.disabled]="disabledAfterTransmit()"
                            formControlName="{{'field' + field.fieldId}}" />                            
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'Email' && visibleFields.indexOf(field.fieldId) !== -1" 
                  class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" value="{{field.fieldValue}}"
                            id="{{'field-' + i + '-' + field.fieldId}}"
                            class="form-control form-control-sm" [attr.disabled]="disabledAfterTransmit()"
                            formControlName="{{'field' + field.fieldId}}" />                            
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                           <em class="text-danger" *ngIf="isValidEmail(field.fieldId)">Invalid Email</em>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'EmailWithoutConfirmation' && visibleFields.indexOf(field.fieldId) !== -1" 
                  class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input type="text" value="{{field.fieldValue}}"
                            id="{{'field-' + i + '-' + field.fieldId}}"
                            class="form-control form-control-sm" [attr.disabled]="disabledAfterTransmit()"
                            formControlName="{{'field' + field.fieldId}}" />                            
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                           <em class="text-danger" *ngIf="isValidEmail(field.fieldId)">Invalid Email</em>
                  </div>
                </div>
                <div class="form-group row" *ngIf="field.fieldType ==  'Info'  && displayField(field.fieldId, field.fieldValue)" id="{{'field-' + field.fieldId}}">
                  <div class="col-md-12">
                    <p class="card-text">{{field.fieldDescription}}</p>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'InsuredInfo' && visibleFields.indexOf(field.fieldId) !== -1" 
                  class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldValue}}
                    </label>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'Date'" class="form-group row">
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5">
                    <input type="date" value="{{field.fieldValue}}"
                           id="{{'field-' + i + '-' + field.fieldId}}" [attr.disabled]="disabledAfterTransmit()"
                           class="form-control form-control-sm" formControlName="{{'field' + field.fieldId}}" (blur)="onDateChange(field.fieldDescription, claimScreenDetail.description, field.fieldId)" />
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>
                <div *ngIf="field.fieldType == 'TextWithCharacterLimit' && visibleFields.indexOf(field.fieldId) !== -1" 
                     class="form-group row">                    
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5">
                    <input type="text" value="{{field.fieldValue}}" maxlength="200" 
                           id="{{'field-' + i + '-' + field.fieldId}}"
                           class="form-control form-control-sm" placeholder="Maximum of 200 characters allowed" 
                           formControlName="{{'field' + field.fieldId}}"  [attr.disabled]="disabledAfterTransmit()"/>
                           <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                           <em class="text-danger" *ngIf="isMaxLengthExceededForText(field.fieldId, i, 200)">Exceeded 200 characters</em>
                           <em class="text-danger" *ngIf="hasValue(field.fieldId, i)">{{displayCharCountForText(field.fieldId, i, 200)}} character(s) left</em>
                  </div>                  
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>
                <div *ngIf="field.fieldType == 'TextArea'  && visibleFields.indexOf(field.fieldId) !== -1" class="form-group row">
                  <div class="col-md-12">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-12">
                    <textarea value="{{field.fieldValue}}" maxlength="500" (keyup)="displayCharCount(field.fieldId, i)"
                              id="{{'field' + i + field.fieldId}}" [attr.disabled]="disabledAfterTransmit()"
                              class="form-control form-control-sm" placeholder="Maximum of 500 characters allowed"
                              formControlName="{{'field' + field.fieldId}}"></textarea>
                              <em class="text-danger" *ngIf="isRequired(field.fieldId, i)">Required</em>
                              <em class="text-danger" *ngIf="isMaxLengthExceeded(field.fieldId, i)">Exceeded 500 characters</em>
                              <em class="text-danger" *ngIf="hasValue(field.fieldId, i)">{{displayCharCount(field.fieldId, i)}} character(s) left</em>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'SATextArea'  && visibleFields.indexOf(field.fieldId) !== -1" class="form-group row">
                  <div class="col-md-12">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-12">
                    <textarea value="{{field.fieldValue}}"
                              id="{{'field' + i + field.fieldId}}" style="height: 400px;"
                              class="form-control form-control-sm"
                              formControlName="{{'field' + field.fieldId}}"></textarea>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'Document'" class="form-group row">
                  <div class="col-md-6">
                    <b>{{field.fieldDescription}}</b>
                  </div>
                  <div class="col-md-3">
                    <a style="cursor:pointer" [download]="field.additionalData"
                     *ngIf="checkWordFileType(field)" 
                     [href]="'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + field.fieldValue | safeResource"><i class="fa fa-file-word-o" style="font-size:48px;color:blue"></i></a>
                    <a style="cursor:pointer" [download]="field.additionalData"
                     *ngIf="checkExcelFileType(field)" 
                     [href]="'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + field.fieldValue | safeResource"><i class="fa fa-file-excel-o" style="font-size:48px;color:green"></i></a>
                    <a style="cursor:pointer" (click)="openImage($event, field.fieldValue)">
                      <img id="{{'img' + field.fieldId}}" [hidden]="field.fieldValue === ''"
                         [src]="field.fieldValue" *ngIf="field.fieldValue !== '' && field.fieldValue.indexOf('data:image') !== -1"
                         [class.thumbnail]="field.fieldValue !== ''" />
                        </a>
                         <button type="button" class="btn float-left" (click)="openpdf($event, field.fieldValue)" 
                         [hidden]="field.fieldValue === ''" *ngIf="field.fieldValue !== '' && field.fieldValue.indexOf('data:application/pdf') !== -1">
                          <i class="fa fa-file-pdf-o" style="font-size:48px;color:red" [attr.data-index]="field.fieldOrderId-1"></i>
                        </button>
                    <button type="button" class="btn float-right" (click)="deleteDocument($event, field.fieldId)" [hidden]="field.fieldValue === ''">
                      <i class="fa fa-lg fa-trash" style="color:red" [attr.data-index]="field.fieldOrderId-1"></i>
                    </button>
                  </div>
                  <div class="col-md-3 form-group custom-file form-control-sm">
                    <input type="file" class="form-control custom-file-input" id="{{'fileInput' + field.fieldId}}"
                           (change)="onFileChange($event, field.fieldId)" accept="*" formControlName="{{'field' + field.fieldId}}" />
                    <label for="{{'fileInput' + field.fieldId}}" class="custom-file-label">Choose File</label>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'Checkbox'" class="form-group row">
                  <div class="col-md-6 custom-control custom-checkbox">
                    <input [id]="field.fieldId" type="checkbox" class="custom-control-input" formControlName="{{'field' + field.fieldId}}">
                    <label [for]="field.fieldId" class="custom-control-label">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                </div>                
                <div *ngIf="(field.fieldType | lowercase) == 'dropdown'" class="form-group row">
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5 custom-control custom-checkbox">
                    <select id="{{'field-' + i + '-' + field.fieldId}}" 
                            formControlName="{{'field' + field.fieldId}}" 
                            [attr.disabled]="disabledAfterTransmit()">
                      <option value="0">--Select--</option>
                      <option *ngFor="let fieldValue of field.fieldValues" value={{fieldValue.id}}>
                        {{fieldValue.name}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div> 
                <div *ngIf="(field.fieldType | lowercase) == 'statedropdown'" class="form-group row">
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5 custom-control custom-checkbox">
                    <select id="{{'field-' + i + '-' + field.fieldId}}" formControlName="{{'field' + field.fieldId}}" [attr.disabled]="disabledAfterTransmit()">
                      <option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>                
                <div *ngIf="field.fieldType == 'CountryDropdown'" class="form-group row">
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-5 custom-control custom-checkbox">
                    <select id="{{'field-' + i + '-' + field.fieldId}}" formControlName="{{'field' + field.fieldId}}"
                       (change)="onCountryChange($event.target.value)"  
                       [attr.disabled]="disabledAfterTransmit()">
                      <option value="0">--Select--</option>
                      <option *ngFor="let fieldValue of field.fieldValues" value={{fieldValue.name}}>
                        {{fieldValue.name}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1"><em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em></div>
                </div>                
                <div *ngIf="field.fieldType == 'RiskDropdown'" class="form-group row">
                  <div class="col-md-6">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6 custom-control custom-checkbox">
                    <select id="{{'field-' + i + '-' + field.fieldId}}" formControlName="{{'field' + field.fieldId}}" (change)="onRiskChange($event.target.value)">
                      <option value="0">--Select--</option>
                      <option *ngFor="let fieldValue of field.fieldValues" value={{fieldValue.id}}>
                        {{fieldValue.name}}
                      </option>
                    </select>
                  </div>
                </div>      
                <div *ngIf="field.fieldType == 'ContactDropdown'" class="form-group row">
                  <div class="col-md-6">
                    <label [attr.data-contactdata]="field.additionalData" attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-6 custom-control custom-checkbox">
                    <select id="{{'field-' + i + '-' + field.fieldId}}" 
                            formControlName="{{'field' + field.fieldId}}" 
                            (change)="onNameTypeChange($event)"
                            [attr.disabled]="disabledAfterTransmit()">
                      <option value="0">--Select--</option>
                      <option *ngFor="let fieldValue of field.fieldValues" value={{fieldValue.id}}>
                        {{fieldValue.name}}
                      </option>
                    </select>
                  </div>
                </div>                
                <div *ngIf="field.fieldType == 'Grid'" class="form-group row">
                  <div class="col-md-12">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                    <em class="text-danger"  *ngIf="isMandatory(field.fieldId, i)">*</em>
                  </div>
                  <div class="col-md-12">
                    <ag-grid-angular domLayout="autoHeight" class="ag-theme-alpine"
                    [gridOptions]="coverageGridOptions"
                    [rowData]="rowData" rowSelection="multiple"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    (rowSelected) = "onRowSelected($event)"
                    (rowDataChanged)="onRowDataChanged()">
                    </ag-grid-angular>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'ReserveGrid'" class="form-group row">
                  <div class="col-md-12">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-12">
                    <ag-grid-angular domLayout="autoHeight" class="ag-theme-alpine"
                    [rowData]="reserveRowData" rowSelection="multiple"
                    [columnDefs]="reserveColumnDefs"
                    (gridReady)="onReserveGridReady($event)"
                    (rowSelected) = "onReserveRowSelected($event)"
                    [gridOptions]="reserveGridOptions">
                    </ag-grid-angular>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-primary" (click)="addReserve($event)" type="button">Add Reserve</button>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'HistoryGrid'" class="form-group row">
                  <!--<span>History:</span>-->
                  <div class="col-md-12">
                    <label attr.for="{{'field-' + i + '-' + field.fieldId}}">
                      {{field.fieldDescription}}
                    </label>
                  </div>
                  <div class="col-md-12">
                    <ag-grid-angular domLayout="autoHeight" class="ag-theme-alpine"
                    [rowData]="historyRowData" rowSelection="multiple"
                    [columnDefs]="historyColumnDefs"
                    (gridReady)="onHistoryGridReady($event)"
                    [gridOptions]="historyGridOptions">
                    </ag-grid-angular>
                  </div>
                </div>
                <div *ngIf="field.fieldType == 'Hidden'" class="form-group row">
                  <div class="col-md-12 custom-control">
                    <input id="{{'field-' + i + '-' + field.fieldId}}" type="hidden"  formControlName="{{'field' + field.fieldId}}" class="{{field.fieldDescription}}" >
                  </div>
                </div>    
              </div>               
              <div class="col-md-12 pb-5">
                <button id="btnDelete" *ngIf="claimScreenDetail.allowMultiple" type="button" 
                class="btn btn-labeled btn-danger" (click)="deleteEntity(fg)">
                  <span class="pr-2">
                    <i class="fa fa-trash"></i>
                  </span>Delete
                </button>
              </div>
            </div>
          </div>       
          <div class="row">
            <div class="col-md-6">
              <button id="btnSave" *ngIf="displaySave()" type="submit" class="btn btn-labeled btn-colour-1 btn-sm  mr-3">
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>Save
              </button>  
              <button id="btnTransmit" *ngIf="claimScreenDetail.description !== undefined && claimScreenDetail.description === 'VERIFY TRANSMIT DETAILS' &&  claimStatus !== 'Finalized'" type="submit" class="btn btn-labeled btn-colour-1 btn-sm">
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>Transmit
              </button>            
              <div class="alert alert-warning pt-3" role="alert" 
                *ngIf="claimScreenDetail.description !== undefined && claimScreenDetail.description === 'VERIFY TRANSMIT DETAILS' &&  claimStatus !== 'Finalized' && openClaimsMessage !== ''">
                <p aria-live="polite">{{openClaimsMessage}}</p>
              </div>
              <button id="btnSend" *ngIf="displaySendAcknowledgement()" type="submit" class="btn btn-labeled btn-colour-1 btn-sm">
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>Send
              </button>
            </div>
            <div class="col-md-6">
              <button id="btnAdd" *ngIf="claimScreenDetail.allowMultiple" type="button"
                      class="btn btn-labeled btn-colour-1 btn-sm" (click)="addForm()">
                <span class="btn-label">
                  <i class="fa fa-plus"></i>
                </span>Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


