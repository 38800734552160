import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ClaimService } from '../shared/claimservice';
import { catchError, map } from 'rxjs/operators';
import { of } from "rxjs";

@Injectable()
export class ClaimResolver implements Resolve<any>{
  constructor(private claimService: ClaimService) {

  }
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return this.claimService.getClaim(id).pipe(map(result => result),
    catchError((error) => {
        const message = `Retrieval error: ${error.message}`;
      //console.error(error);
      return of({ error: message });
    }));
  }
}
