<form (ngSubmit)="submitForm()">
    <div class="container">
        <div class="row card bg-light">
            <h4 id="h4ScreenDesc" class="h4 card-header banner">GETTING STARTED</h4>
            <div class="row">
                <div class="container pt-3">
                <div class="col-md-12 form-group">
                    <p>
                        <b>
                            Please Enter Policy Number and Named Insured Zip Code in below fields:
                        </b>
                    </p>
                </div>
                <div class="col-md-12 form-group">
                    <label for="txtPolicyNumber">Policy Number:</label>
                </div>
                <div class="col-md-6 form-group">
                    <input [(ngModel)]="policyNumber" required name="policyNumber" class="form-control form-control-sm" id="txtPolicyNumber" type="text" />
                </div>
                <div class="col-md-12 form-group">
                    <label for="txtZipCode">Insured Zip Code:</label>
                </div> 
                    <div class="col-md-6 form-group">
                    <input [(ngModel)]="zipCode" required name="zipCode" class="form-control form-control-sm" id="txtZipCode" type="text" />
                </div>  
            </div>
            </div>
            <div class="card-footer">
                <button id="btnContinue" type="submit" class="btn btn-labeled btn-colour-1 btn-sm">
                    <span class="btn-label">
                        <i class="fa fa-save"></i> |
                        <i class="fa fa-long-arrow-right"></i>
                    </span>Continue
                </button>            
            </div>
        </div>
    </div>
</form>