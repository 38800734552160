import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import{CreateNewClaimService} from '../shared/createnewclaimservice';

@Component({
  selector: 'app-newclaim-component',
  templateUrl: './newclaim.component.html',
  styleUrls: ['newclaim.component.css']
})
export class NewClaimComponent {
  zipCode: Number;
  policyNumber: String;
  constructor(private route: ActivatedRoute, private router: Router, 
    private createNewClaimService:CreateNewClaimService) {

  }

  submitForm(){
      let createNewClaimModel = {policyNumber : this.policyNumber, zipCode: this.zipCode};
      this.createNewClaimService.create(createNewClaimModel).subscribe((response:NewClaimServiceResponse) => { 
        console.log(response);
        this.router.navigate(['/claim/' + response.id]);
      });
  }
}

class NewClaimServiceResponse{
    id:Number;
}